/* You can add global styles to this file, and also import other style files */

fa-icon svg {
    width: 1em;
    height: 1em;
}

.body-container {
    margin-bottom: 100px
}

.bg-primary {
    background-color:   #009933 !important;
}

.btn-primary {
    color: #fff;
    background-color: #009933;
    border-color:  #009933;
}

.btn-primary:hover {
  color: #fff;
  background-color:  #00cc44;
  border-color:  #00cc44;
}

.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(89, 49, 150, 0.5);
          box-shadow: 0 0 0 2px rgba(89, 49, 150, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #00ff55;
  border-color: #00ff55;
}
.btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00802b;
  background-image: none;
  border-color: #00802b;
}


.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  background-color: #00cc44;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #00cc44;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #cbc8d0;
  background-color: transparent;
}

ul li {
  margin-left: 25px;
}

.list-group-item.active {
  font-weight: var(--bs-body-font-weight);
}